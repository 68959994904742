@import "https://fonts.googleapis.com/css?family=Dancing+Script|Quicksand|Oswald";
@import "https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap";
@import "https://fonts.googleapis.com/css2?family=Ubuntu+Mono&display=swap";

@import "~bulma/sass/utilities/initial-variables.sass";

$primary: $cyan;
$link: $primary;

@import "~bulma/bulma.sass";

:root {
    --primary: #{$primary};
    --info: #{$info};
    --success: #{$success};
    --warning: #{$warning};
    --danger: #{$danger};
    --link: #{$link};
}

@import "./style.scss";