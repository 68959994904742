.presentation {
    display: flex;
    gap: 4rem;
    margin: 0 auto;
    width: fit-content;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 6rem;

    @media screen and (min-width: 967px) {
        height: 450px;

        .left {
            width: 250px;
            text-align: right;
        }

        .middle {
            margin: auto 0;
        }

        .right {
            width: 250px;
            margin-top: auto;
        }

        .illustration {
            max-width: 350px;
        }
    }

    @media screen and (max-width: 966px) {
        flex-wrap: wrap;

        .left, .middle, .right {
            flex: 1 1 100%;
            text-align: center;
            max-width: 550px;
            margin: 0 auto;
        }

        .connector {
            display: none;
        }

        .illustration {
            max-width: 250px;
        }
    }
}