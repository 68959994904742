.technology {
    .technology-section {
        margin: 0 auto;
        margin-top: 2rem;

        .technology-section-inner {
            display: flex;
            gap: 2rem;
            align-items: center;
            justify-content: space-between;
        }

        .illustration {
            width: 250px;
            margin: 0 auto;
            display: block;
        }

        .platform-content {
            padding: 0 2rem;
        }

        @media screen and (max-width: 768px) {
            .technology-section-inner {
                flex-wrap: wrap;

                > div {
                    flex: 0 0 100% !important;
                }
            }

            .technology-section-inner-reverse {
                flex-wrap: wrap-reverse;
            }

            // .illustration {
            //     margin-bottom: 2rem;
            // }
        }

        @media screen and (max-width: 1023px) {
            width: calc(100vw - 2 * 2rem);
            max-width: calc(1000px + 2 * 2rem);
            padding: 0 2rem;
        }

        @media screen and (max-width: 769px) {
            padding: 0;
        }

        @media screen and (min-width: 1024px) {
            width: calc(100vw - 2 * 2rem);
            max-width: calc(1000px + 2 * 4rem);
            padding: 0 4rem;
        }

        // @media screen and (max-width: 1200px) {
        //     margin-top: 2rem;

        //     // &.vertical-correction {
        //     //     margin-top: 0;
        //     // }

        //     // &.vertical-correction2 {
        //     //     margin-top: 1rem;
        //     // }
        // }

        // @media screen and (max-width: 1024px) {
        //     &.vertical-correction {
        //         margin-top: 1rem;
        //     }
        // }

        // @media screen and (min-width: 1201px) {
        //     &.vertical-correction {
        //         margin-top: -2rem;
        //     }

        //     &.vertical-correction2 {
        //         margin-top: -2rem;
        //     }
        // }
    }
}

.data-assemble {
    font-family: 'Ubuntu Mono';
    font-weight: 400;
    // color: hsl(348, 86%, 61%);

    @media screen and (max-width: 430px) {
        font-size: 2rem !important;
    }
}