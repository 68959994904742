.footer-columns {
    display: flex;
    justify-content: space-between;
    width: 100%;
    font-size: 80%;
    max-width: 1500px;
    padding: 0 4rem;
    margin: 0 auto;
    align-items: center;
    gap: 2rem;

    @media screen and (max-width: 922px) {
        flex-wrap: wrap;

        > div {
            flex: 0 0 100% !important;
        }
    }
}

.footer-menu-list {
    a {
        border: 1px solid transparent;
    }

    a:hover {
        background: white;
        border: 1px solid hsl(0, 0%, 86%);
    }
}

