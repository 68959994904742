// .pythia-loader {
//     margin:0 auto;
//     display:block;
//     width:30%;
//     max-width:114px;
//     position:relative;
//     -webkit-backface-visibility: hidden;
//     margin-top: 10px;
//     margin-bottom: 10px;
// }

// section#home{
//     background: url('/img/binder-anim/binder-anim12.jpg');
//     background-position:center;
//     background-repeat: no-repeat;
//     background-size: cover;
// }


// #main {
//     margin:0 auto; 
//     text-align:center;
//     font-family: 'Quicksand', sans-serif;
// }

// #main .logo {

// position: absolute;
//     width: 100%;
//     top: 50%;
//     margin-top: -200px;
// }

// #main .logo_text img {
// width:50%;
// max-width:500px;
// margin:0 auto;
// animation: logoIn 2s ease-in-out forwards; /* assign animation to logo */
// animation-delay: 1s;
//     opacity: 0;
// }
                        
                    
// #main .logo h1 {
// width: 50%;
// height: 30px;
// display: block;
// margin: 30px auto;
// font-size: 2.5em;
// // font-weight:bold;
// // line-height: 30px;
// // font-variant: petite-caps;
// // font-weight: 400;
// animation: logoIn 2s ease-in-out forwards;
// animation-delay: 2s;
// text-align:center;
// color:black;
// letter-spacing:2px;
// opacity:0;
// }

// #main .logo h1 {
//     width: 100%;
//     height: 60px;
//     display: block;
//     margin: 60px auto;
//     line-height: 60px;
//     font-size: 3em;
    
//     }

// .outer_icon {
//     width:100%;
//     animation: outerLogoIn 1s ease-out forwards;
//     animation-delay: 2s;
//     opacity: 0;
//     z-index:2;
//     }
//     .outer_icon img {
//         width: 100%;
//         object-fit: contain;
//         display:block;
//     }

//     .inner_icon {
//         z-index: 3;
//         top: 10%;
//         left:10.5%;
//         width:80%;
//         position: absolute;
//         animation: innerLogoIn 1s ease-out forwards;
//         animation-delay: 2s;
//         opacity: 0;
//         -webkit-backface-visibility: hidden;
//     }
//     .inner_icon img {
//         width: 100%;
//         margin:0 auto;
//         object-fit: contain;
//         display:block;
//     }

//     .scroll-link {
//         position: absolute;
//         bottom: 2%;
//         left: 50%;
//         transform: translateX(-50%);
//         background-color: transparent;
//         width: 150px;
//         /* animation: logoIn 3s ease-in-out forwards; */
//         line-height:2.8;
//         font-size: 2rem;
//     }


//     @keyframes logoIn{
//         from{
//             transform: translateY(-30px);
//             opacity: 0;
//         }
//         to{
//             transform: translateY(0);
//             opacity: 1;
//         }
//     }
    
//     @keyframes outerLogoIn{
    
//         from{
//             transform: rotate(180deg);
//             opacity: 0;
//         }
//         to{
//             transform: translateY(0);
//             opacity: 1;
//         }
    
//     }
    
//     @keyframes innerLogoIn{
    
//         from{
//             transform: rotate(-180deg);
//             opacity: 0;
//         }
//         to{
//             transform: translateY(0);
//             opacity: 1;
//         }
    
//     }

.main-hero {
    position: relative;
    width: 100vw;

    @media screen and (max-height: 600px) {
        height: 600px;
    }

    @media screen and (min-height: 601px) {
        height: 100vh;
    }

    .hero-button-container {
        @media screen and (max-height: 600px) {
            display: none;
        }

        @media screen and (min-height: 601px) {
            position: absolute;
            bottom: 0.5rem;
            left: 0;
            right: 0;
        }
    }
}

.button.hero-button {
    display: block;
    margin: 0 auto;
    background-color: transparent !important;
    border: 0 !important;
    outline: none !important;
    box-shadow: none !important;
}

.button.demo-link {
    margin-top: 10px;
    background: transparent;
    color: black;
    background: #ffffff66;
    // border: 1px solid black;
    border: 1px solid transparent;

    &:hover, &:focus {
        background: #ffffff66;
        border: 1px solid black;
        color: black;
    }

    animation: logoIn 2s ease-in-out forwards;
    animation-delay: 2s;
    opacity:0;
}