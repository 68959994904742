.contact-illustration {
    margin: 0 auto;
    display: block;

    @media screen and (max-height: 600px) {
        height: 200px;
    }

    @media screen and (min-height: 601px) {
        height: 30vh;
    }
}