.header {

    // height: 100px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 3;
    width: 100%;
    padding: 10px 0px 10px;
    transition: top .2s ease-in-out 0s, background .2s ease 0s;
    animation-iteration-count: 1;
    transform-origin: 50% 50%;
    animation-fill-mode: forwards;
}

.header .hamburger {
    // display:block;
    // width:90px;
    position: fixed;
    top: 30px;
    width: 60px;
    height: 45px;
    right: 20px;
    z-index: -1;
    display: none;
}

.header .minilogo {
    opacity: 0;
    display: none;
    position: absolute;
    top: 10px;
    left: 25px;
    height: 90px;
    // width: 120px;

    // img {
    //     width: auto;
    // }

    @media screen and (max-width: 1200px) {
        .text {
            display: none;
        }
    }

    @media screen and (max-width: 967px) {
        .brand {
            display: none;
        }
    }
}

.header.mini .minilogo {
    display:block;
    opacity:1;
    }

.mini {
    background: white;
    // border-bottom: 1px solid black;
}

ul.main-menu {
    margin-bottom: 0;
    width: 100%;
    text-align: center;
    margin-left: 0;
    list-style: none outside;
    margin-left: 0;

    li {
        vertical-align: top;
        display: inline-block;
        margin-bottom: 0;
        border-radius: 9999px;
        margin: 0 5px;
        border: 1px solid transparent;

        &:hover {
            background: white;
            border: 1px solid white;
        }

        &.is-active {
            // background: red !important;
            background: #3e8ed0 !important;
            border: 1px solid #3e8ed0;
            a {
                color: white !important;
            }
        }

        a {
            position: relative;
            text-decoration: none;
            // font-family: 'Oswald', sans-serif;
            font-family: Quicksand, sans-serif;
            color: black;
            display: block;
            padding: 0.25rem 25px;
            font-size: 15px;
            font-weight: 500;
            letter-spacing: 1px;
            text-transform: uppercase;
            border-bottom: 1px solid transparent;
        }
    }
}

.mini .contact-menu-li {
    border: 1px solid black;
}

.mini ul.main-menu li:hover {
    background: hsl(0, 0%, 96%);
    border: 1px solid hsl(0, 0%, 96%) !important;
}

.menu-small {
    overflow-y: auto;
}

.menu-small .menu {
    // position: absolute;
    // top: 50%;
    // transform: translateY(-50%);
    // width: 100%;
    position: "absolute";
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: center;
    padding: 5rem 2rem;

    ul {
        margin: 0 auto;
        width: fit-content;
    }

    li {
        display: block;
        margin: 40px;

        a {
            font-size: 150%;
        }
    }
}

#nav-icon {
    outline: none !important;
    background: transparent;
    cursor: pointer;
    text-transform: none;
    border: 0;
    margin: 0;
    padding: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
}

#nav-icon span {
    display: block;
    position: absolute;
    height: 9px;
    width: 100%;
    background: #0D8499;
    border-radius: 9px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
}

#nav-icon span:nth-child(1) {
    top: 0px;
}

#nav-icon span:nth-child(2) {
    top: 18px;
}

#nav-icon span:nth-child(3) {
    top: 36px;
}

#nav-icon.open span:nth-child(1) {
    top: 18px;
    -webkit-transform: rotate(135deg);
    -moz-transform: rotate(135deg);
    -o-transform: rotate(135deg);
    transform: rotate(135deg);
}

#nav-icon.open span:nth-child(2) {
    opacity: 0;
    left: -60px;
}

#nav-icon.open span:nth-child(3) {
    top: 18px;
    -webkit-transform: rotate(-135deg);
    -moz-transform: rotate(-135deg);
    -o-transform: rotate(-135deg);
    transform: rotate(-135deg);
}