@charset "utf-8";

button {
    font-family: 'Quicksand'
}
/********************** Global CSS ************************
/****** 			TITELLE.COM		   			*********** 
/* This css file sets the default style for all the pages 
/****************** Author: J. JORDA  *********************
/**********************************************************
Version : 0.1
Created: 04/18/19  	         							  */

/*	=TABLE OF CONTENTS
	------------------------------------------------------
	=RESET
	=BASIC
	=CONTENT
	=SMARTPHONES
*/



/*	=RESET
	------------------------------------------------------ */

/* reset all browser predefined styles */
html, body, div, span, object, h1, h2, h3, h4, h5, h6, p, blockquote, cite, a, abbr, acronym, address, em, strong, img, dl, dt, dd, ol, ul, li, form, input, fieldset, legend, label, table, caption, tbody, tfoot, thead, tr, th, td { padding: 0; border: 0; outline: 0; margin: 0; font-size: 1.0em; font-weight: inherit; font-family: inherit; font-style: inherit; vertical-align: baseline; }
ol, ul { list-style: none; }


/*	=BASIC
	------------------------------------------------------ */

html,body {
    margin: 0;
    height:100%;
    -webkit-text-size-adjust: 100%;
}
html {
overflow:scroll;
width:100%;
scroll-behavior:smooth;
}

body {
font-family: 'Quicksand', sans-serif;
position:relative;
}


canvas {
    background: white;
    display:none;
}
canvas.active {
    background: white;
    display:block;
}

.active {
display:block;
}
.inactive {
display:none;
}

.bg_white {
    background-color: white;
}

/**********************************************/
/*		NAVIGATION		      */
/**********************************************/

.contact_nav {
display:none;
}

/* Top and Bottom borders go out */
ul.topBotomBordersOut a:before, ul.topBotomBordersOut a:after
{
    position: absolute;
    left: 0px;
    width: 100%;
    height: 2px;
    background: #000;
    content: "";
    opacity: 0;
    transition: all 0.3s;
}

ul.topBotomBordersOut a:before
{
    top: 0px;
    transform: translateY(10px);
}

ul.topBotomBordersOut a:after
{
    bottom: 0px;
    transform: translateY(-10px);
}

ul.topBotomBordersOut a:hover:before, ul.topBotomBordersOut a:hover:after
{
    opacity: 1;
    transform: translateY(0px);
}


body.menu-active nav {
    left: 0px;
}

#main {
position: absolute;
top: 0;
left: 0;
bottom: 0;
right: 0;
margin:0 auto; 
text-align:center;
font-family: 'Quicksand', sans-serif;
}

#main .logo {

position: absolute;
    width: 100%;
    top: 50%;
    // margin-top: -200px;
    transform: translateY(-50%);
}

#main .logo_text img {
width:50%;
max-width:500px;
margin:0 auto;
animation: logoIn 2s ease-in-out forwards; /* assign animation to logo */
animation-delay: 1s;
    opacity: 0;
}
                        
                 
#main .logo h1 {
// height: 30px;
display: block;
margin: 30px auto;
font-size: 2.5em;
// font-weight:bold;
font-weight: 500;
// line-height: 30px;
// font-variant: petite-caps;
animation: logoIn 2s ease-in-out forwards;
animation-delay: 2s;
text-align:center;
color:black;
letter-spacing:2px;
opacity:0;
}

.pythia-loader {
margin:0 auto;
display:block;
width:30%;
max-width:114px;
position:relative;
-webkit-backface-visibility: hidden;
margin-top: 10px;
margin-bottom: 10px;
}

.outer_icon {
width:100%;
animation: outerLogoIn 1s ease-out forwards;
animation-delay: 2s;
opacity: 0;
z-index:2;
}
.outer_icon img {
    width: 100%;
    object-fit: contain;
    display:block;
}


.inner_icon {
    z-index: 3;
    top: 10%;
    left:10.5%;
    width:80%;
    position: absolute;
    animation: innerLogoIn 1s ease-out forwards;
    animation-delay: 2s;
    opacity: 0;
    -webkit-backface-visibility: hidden;
}
.inner_icon img {
    width: 100%;
    margin:0 auto;
    object-fit: contain;
    display:block;
}

#canvas_bg {
    opacity:1;
    -webkit-transition: opacity 5s ease-in;
    -moz-transition: opacity 5s ease-in;
       -ms-transition: opacity 5s ease-in;
        -o-transition: opacity 5s ease-in;
               transition: opacity 5s ease-in;
    animation-delay: 2s;
    width:100%;
    height:auto;
}
#canvas_bg.stop {
 opacity:0.7;
}


@keyframes logoIn{
    from{
        transform: translateY(-30px);
        opacity: 0;
    }
    to{
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes outerLogoIn{

    from{
        transform: rotate(180deg);
        opacity: 0;
    }
    to{
        transform: translateY(0);
        opacity: 1;
    }

}

@keyframes innerLogoIn{

    from{
        transform: rotate(-180deg);
        opacity: 0;
    }
    to{
        transform: translateY(0);
        opacity: 1;
    }

}


.scroll {
	animation-name: scroll;
	animation-duration: 1.5s;
	animation-timing-function: cubic-bezier(0.650, -0.550, 0.250, 1.500);
	animation-iteration-count: infinite;
	transform-origin: 50% 20.5px;
	will-change: transform, opacity;
	opacity: 1;
}

.scroll-link {
    position: absolute;
    bottom: 2%;
    left: 50%;
    transform: translateX(-50%);
    background-color: transparent;
    width: 150px;
    /* animation: logoIn 3s ease-in-out forwards; */
}



/*********************************

	   ANIMATIONS

**********************************/

@keyframes scroll {

	0%, 20% {
		transform: translateY(0) scaleY(1);
	}

	100% {
		transform: translateY(36px) scaleY(2);
		opacity: 0;
	}

}

.parallax:after {
content:" ";
position:absolute;
top:0;
right:0;
bottom:0;
left:0;
transform:translateZ(-1px) scale(-1.5);
z-index:-1;
background: url('/img/website_bg.png') no-repeat left top;
background-size: 30%;
}

/*********************************

	   CONTENT / SUBSECTIONS

**********************************/


.subsection {
	max-width:980px;
	
	margin: 0 auto;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center; 
}

.main-subsection {
    min-height: 100vh;
}


.subsection h1{
display:block;
margin-top:100px;
text-align:center;
}
.pythia-content {
height:100%;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: relative;
}

section#team {
    height: auto;
}

.team .pythia-content {
align-items: initial;
margin:50px auto;
}

.team .pythia-content div {
width:33%;
margin-bottom:30px;
}

.team .pythia-content div.wide {
width:50%;
margin-bottom:30px;
}

.team .pythia-content div.mugshot{

width:80%;
min-height:190px;
/*height:30%; */
margin:0 auto;
/*margin-bottom:30px; */
}

.mugshot img {
border-radius:50%;
width: 70%;
    display: block;
    margin:0 auto;
}

.team .pythia-content h2{
    font-family: 'Quicksand', sans-serif;
    font-weight: bold;
    letter-spacing: 3px;
}

.team .pythia-content h3 {
margin-bottom:10px;
margin-top: 10px;
}

.team .pythia-content p {
font-size:0.85em;
line-height:1.8em;
letter-spacing: 1px;
    width: 90%;
   padding: 15px;
   margin: 0 auto;
/*text-align:justify;*/
}

/*	=SMARTPHONES
	------------------------------------------------------ */

// @media screen and (min-width: 400px) and (max-width: 1024px) {

// .subsection {
// width:90%;
// }

// nav#navigation {
//   position: fixed;
//   top: 0;
//   left: -50%;
//   right: unset;
//   bottom: 0;
//   display: block;
//   float: left;
//   width: 100%;
//   margin-left: 0;
//   max-width: 50%;
//   height: 100%;
//   -moz-transition: all 300ms;
//   -webkit-transition: all 300ms;
//   transition: all 300ms;
//   z-index: 200;
//   background: white;
//   padding:0;  
// }



// nav#navigation > ul {
//     margin-top: 140px;
// }
// nav#navigation li {
// display: block;
// margin:0;
// text-align:left;
// border-top: 2px solid rgba(255,255,255,0.03);
// }
// nav#navigation li:first-child {
//     border-top: none;
// }

// .contact_nav {
// display:block;
// }

// nav#navigation.active {
// background:#444;
// -webkit-transform: translateX(100%) translateZ(0);
// -moz-transform: translateX(100%) translateZ(0);
// -ms-transform: translateX(100%) translateZ(0);
// transform: translateX(100%) translateZ(0);
// }

// nav.active li a {
// line-height:45px;
// font-size:45px;
// color:white;
// margin-bottom:20px;
// }

// ul.topBotomBordersOut a:before, ul.topBotomBordersOut a:after {
// display:none;
// }

// #main .logo h1 {
// width: 100%;
// height: 60px;
// display: block;
// margin: 60px auto;
// line-height: 60px;
// font-size: 3em;

// }

// canvas#canvas_bg {
//     display: none;
// }
// section#home{
//     background: url('/img/binder-anim/binder-anim12.jpg');
//     background-position:center;
//     background-repeat: no-repeat;
//     background-size: cover;
// }

// .scroll-link{
// line-height:2.8;
// font-size: 2rem;
// }


// .contact {
// width: 20%;
// height: 20%;
// }

// }