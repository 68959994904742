.persons {
    max-width: 1200px;
    padding: 0 4rem;
    margin: 0 auto;
}

.person {
    flex-grow: 1;
    flex-basis: 0;
    min-width: 40%;
}

@media screen and (max-width: 700px) {
    .persons {
        padding: 0 2rem;
        flex-wrap: wrap;
    }

    .person {
        flex: 1 1 100%;
    }
}