.careers-hero {
    position: relative;

    padding-top: 6rem;

    @media screen and (min-height: 701px) and (min-width: 601px) {
        padding-top: 0;
        height: 100vh;
    }

    .vertical-aligner {
        @media screen and (min-height: 701px) and (min-width: 601px) {
            position: absolute;
            top: 50%;
            left: 0;
            right: 0;
            transform: translateY(-50%);
        }
    }

    .inner {
        padding: 0 2rem;
        margin: 0 auto;
        max-width: 800px;
        text-align: center;
    }

    .illustration {
        height: 210px;

        @media screen and (min-height: 701px) and (min-width: 601px) {
            height: 30vh;
        }

        margin-bottom: 2rem;
    }

    .hero-button-container {
        display: none;

        @media screen and (min-height: 701px) and (min-width: 601px) {
            display: block;
            position: absolute;
            bottom: 0.5rem;
            left: 0;
            right: 0;
        }
    }
}